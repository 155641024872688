











































































import { Component, Vue } from "vue-property-decorator";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsString } from "@/utils/utils-string";
import pacienteModule from "@/store/modules/paciente-module";
import { paciente } from "@/shared/dtos/paciente-dto";
import hist_clinicoModule from "@/store/modules/historial_clinico/hist_clinico-module";
import { hist_clinico } from "@/shared/dtos/Historial_clinico/hist_clinico";
import cirugiaModule from "@/store/modules/historial_clinico/cirugia-module";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    InformacionBasicaPaciente: () =>
      import(
        "@/views/pacientes/información_paciente/informacion_basica_paciente.vue"
      ),
    HistClinicoCombo: () =>
      import("@/components/ComboEtiquetas/HistClinicoCombo.vue"),
    TooltipButton: () => import("@/components/Buttons/TooltipButton.vue"),
    TableSelect: () => import("@/components/DataTableSelect/TableSelect.vue"),
  },
})
export default class PacienteHistCirujias extends Vue {
  public id_paciente: number = 0;
  public numVacunas: number = 1;
  public numDiagnosticos: number = 2;
  public numMedicamentos: number = 3;
  public numCirugias: number = 4;
  public numOtros: number = 5;
  public show_dialog: boolean = false;
  public esta_loading: boolean = true;

  //variables a cambiar
  public num_historial: number = 4;
  public nombre_historial: string = "Cirugías";
  public itemKey: string = "id";

  public hist_clinicos: hist_clinico[] = [];
  public data_selected: hist_clinico[] = [];

  created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
      pacienteModule.getpaciente(
        new paciente({
          numero: this.id_paciente,
        })
      );

      hist_clinicoModule
        .gethist_clinico_del_paciente_by_tipo({
          id_paciente: this.id_paciente,
          id_tipo: this.num_historial,
        })
        .then(() => {
          this.hist_clinicos = hist_clinicoModule.hist_clinicos;
        });

      if (this.data_source.length === 0) {
        //Especial para cirujias
        cirugiaModule.get_all_cirugias().then(() => {
          this.esta_loading = false;
        });
      } else {
        this.esta_loading = false;
      }
    }
  }

  public get data_source() {
    return cirugiaModule.cirugias;
  }

  public get paciente() {
    return pacienteModule.paciente;
  }

  public get tiene_email() {
    return !UtilsString.IsNullOrWhiteSpace(
      UtilsString.ValueOf(this.paciente.email)
    );
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        value: "nombre",
      },
    ];
  }
  public get tolheight() {
    return this.$vuetify.breakpoint.height - 412;
  }

  public refrescar() {
    hist_clinicoModule
      .gethist_clinico_del_paciente_by_tipo({
        id_paciente: this.id_paciente,
        id_tipo: this.num_historial,
      })
      .then(() => {
        this.hist_clinicos = hist_clinicoModule.hist_clinicos;
      });
  }

  public item_seleccionado(e: any) {
    let eti: hist_clinico = new hist_clinico();
    eti.descripcion = e.nombre;
    eti.id_paciente = this.paciente.numero;
    eti.id_tipohistclinico = this.num_historial;
    hist_clinicoModule.guardarhist_clinico(eti).then(() => {
      this.refrescar();
    });
  }
  public item_deseleccionado(e: any) {
    let eti: hist_clinico = new hist_clinico();
    eti.descripcion = e.nombre;
    eti.id_paciente = this.paciente.numero;
    eti.id_tipohistclinico = this.num_historial;

    let index_delete = this.hist_clinicos.findIndex(
      (x) => x.descripcion === eti.descripcion
    );
    hist_clinicoModule
      .eliminarhist_clinico_eliminar(this.hist_clinicos[index_delete])
      .then(() => {
        this.refrescar();
      });
  }
  public getpdfhistclinico(id_tipo: number) {
    hist_clinicoModule.getpdf_historial_clinico({
      id_tipo: id_tipo,
      id_paciente: this.paciente.numero,
    });
  }
  public send_email_histclinico(id_tipo: number) {
    hist_clinicoModule.send_email_historial_clinico({
      id_tipo: id_tipo,
      id_paciente: this.paciente.numero,
    });
  }
}
