import { store } from "@/store/store";
import { cirugia } from "@/shared/dtos/Historial_clinico/cirugia";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "cirugiaModule",
  store,
  dynamic: true,
})
class cirugiaModule extends VuexModule {
  public cirugias: cirugia[] = [];
  public cirugias_activas: cirugia[] = [];
  public cirugia: cirugia = new cirugia();

  @Action({ commit: "onGetcirugias" })
  public async getcirugias(nombre: string) {
    return await ssmHttpService.get(
      API.cirugia + "/cirugias_like?like_nombre=" + nombre,
      null,
      false
    );
  }

  @Action({ commit: "onGetcirugias" })
  public async get_all_cirugias() {
    return await ssmHttpService.get(API.cirugia , null, false);
  }

  @Action({ commit: "onGetcirugias_activas" })
  public async gethist_clinicos_cirujias_acivas(id_paciente: number) {
    return await ssmHttpService.get(
      API.hist_clinico + "/cirujias_acivas/" + id_paciente
    );
  }

  @Action({ commit: "onGetcirugia" })
  public async getcirugia(id: any) {
    return await ssmHttpService.get(API.cirugia + "/" + id);
  }

  @Action
  public async guardarcirugia(cirugia: cirugia) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.cirugia, cirugia.toJson());
  }

  @Action
  public async modificarcirugia(cirugia: cirugia) {
    await ssmHttpService.put(API.cirugia, cirugia);
  }

  @Action
  public async eliminarcirugia(cirugia: cirugia) {
    await ssmHttpService.delete(API.cirugia + "/" + cirugia.id, null, false);
  }

  @Mutation
  public onGetcirugias(res: cirugia[]) {
    this.cirugias = res ? res.map((x) => new cirugia(x)) : [];
  }

  @Mutation
  public onGetcirugias_activas(res: cirugia[]) {
    this.cirugias_activas = res ? res.map((x) => new cirugia(x)) : [];
  }
  @Mutation
  public Addcirugias(res: cirugia[]) {
    for (let i = 0; i < res.length; i++) {
      this.cirugias.push(res[i]);
    }
  }

  @Mutation
  public onGetcirugia(res: cirugia) {
    this.cirugia = new cirugia(res);
  }
}
export default getModule(cirugiaModule);
